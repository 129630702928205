<template>
  <v-row>
    <v-col cols="3" class="mb-4">
      <v-btn block @click="navigate('0')">Workshop attendees</v-btn>
    </v-col>

    <v-col cols="3" class="mb-4">
      <v-btn block @click="navigate('1')">Client Aims</v-btn>
    </v-col>

    <v-col cols="3" class="mb-4">
      <v-btn block @click="navigate('2')">Team Aims</v-btn>
    </v-col>

    <v-col cols="3" class="mb-4">
      <v-btn block @click="navigate('3')">Pain Points</v-btn>
    </v-col>

    <v-row justify="center">
      <WorkshopInput />
    </v-row>
  </v-row>
</template>

<script>
import WorkshopInput from "@/components/WorkshopInput.vue";

import WorkshopAttendees from "@/components/WorkshopAttendees.vue";

import ClientAims from "@/components/ClientAims.vue";
import TeamAims from "@/components/TeamAims.vue";
import ChallengesAndOpportunities from "@/components/ChallengesAndOpportunities.vue";
import ActionPlan from "@/components/ActionPlan.vue";
import PainPoints from "@/components/PainPoints.vue";

export default {
  props: ["expandAll", "open"],

  components: {
    WorkshopAttendees,
    ClientAims,
    TeamAims,
    ChallengesAndOpportunities,
    ActionPlan,
    PainPoints,
    WorkshopInput,
  },
  data: () => ({
    panels: [],
    items: 2,

    headers: [
      { name: "Workshop - Challenges & Opportunities" },
      {
        name: "Workshop - Action Plan (Pick the top 4-5 items from the prioritisation)",
      },
    ],
  }),
  watch: {
    expandAll: {
      handler() {
        if (this.expandAll == true) {
          this.all();
        } else {
          this.none();
        }
      },
      immediate: true,
    },
  },

  mounted() {
    if (this.$route.params.tab != undefined) {
      // only runs when URL has panelNumber inside
      let tab = parseInt(this.$route.params.tab);
      this.panels[0] = tab; // opens up expansion panel based on number in URL

      setTimeout(() => {
        this.$vuetify.goTo(500, { duration: 1000 }); // scrolls down to opened component automatically
      }, 500);
    }
  },
  methods: {
    scrollToElement() {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: "smooth",
      });
    },

    all() {
      this.panels = [...Array(this.items).keys()].map((k, i) => i);
    },
    // Reset the panel
    none() {
      this.panels = [];
    },
    navigate(panelNumber) {
      console.log(this.$route.params);

      this.$router.push({
        path: "/preparation/" + panelNumber + "", // pushes panelNumber to PreparationExpansionPanel
        component: this.$route.params,
      });
    },
  },
};
</script>

<style src="../styles/expansionpanels.scss" lang="scss"></style>
