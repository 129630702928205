var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_vm._v(" In this step we would like you to consider the client aims, team aims and pain points and turn them into challenges and opportunties on the project. "),_c('br'),_vm._v(" Once complete, in the next section you will chose 4-5 to focus on and add detail. ")])],1),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"1"}}),_c('v-col',{attrs:{"cols":"5"}},[_vm._v(" Want to use a whiteboard? "),_c('v-btn',{staticClass:"ma-2",attrs:{"dark":"","color":"blue","href":_vm.whiteboardTemplateLink,"target":"_blank"}},[_vm._v("Get the template")]),_c('v-tooltip',{attrs:{"top":"","max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v("With your digital facilitator, unpack the team aims, client aims and pain points and convert them into challenges and opportunities. Use the whiteboard as a template, feel free to modify to your needs. You could use this space to explore how the existing workflow looks and its challenges. The priority of the challenges/opportunities will be dependant on the project.")])])],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":"Paste your Whiteboard URL here"},on:{"change":function($event){return _vm.updateLink()}},model:{value:(_vm.LinkToWhiteboard),callback:function ($$v) {_vm.LinkToWhiteboard=$$v},expression:"LinkToWhiteboard"}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"top":"","max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v("Copy and paste from the existing template into your own DIP specific whiteboard and paste the share link in this box for future reference.")]),_c('v-col',{attrs:{"cols":"1"}})],1)],1)],1),_c('br'),_c('v-divider',{staticClass:"my-6"}),_c('v-dialog',{attrs:{"max-width":"320"},model:{value:(_vm.confirmDeleteDialog),callback:function ($$v) {_vm.confirmDeleteDialog=$$v},expression:"confirmDeleteDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Confirm client aim delete")]),_c('v-card-text',[_vm._v("Are you sure you would like to delete this client aim?")]),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"red darken-3","text":""},on:{"click":function($event){_vm.confirmDeleteDialog = false;
            _vm.selectedItem = null;}}},[_vm._v("Cancel")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.confirmDeleteDialog = false;
            _vm.deleteItem(_vm.selectedItem);}}},[_vm._v("Delete")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"600"},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline grey lighten-2"},[_vm._v("Edit Challenge / Opportunity")]),_c('v-container',{staticClass:"pa-5"},[_c('v-form',{model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',{staticClass:"pb-4"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":"Edit Opportunity Statement","rules":[_vm.rules.descPresence, _vm.rules.descLength],"rows":"2","value":_vm.newItem.description},on:{"change":function($event){_vm.changed = true}},model:{value:(_vm.newItem.description),callback:function ($$v) {_vm.$set(_vm.newItem, "description", $$v)},expression:"newItem.description"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('DisciplineSelector',{attrs:{"outerButton":{ button: false, symbol: '' },"initialValue":_vm.newItem.discipline},on:{"update:model":_vm.updateD}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"red darken-3","text":""},on:{"click":function($event){_vm.editDialog = false;
            _vm.wipe();}}},[_vm._v("Cancel")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","disabled":_vm.validationCheck()},on:{"click":function($event){_vm.editDialog = false;
            _vm.editItem(_vm.selectedItem, _vm.newItem);}}},[_vm._v("Save changes")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }