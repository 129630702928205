<template>
  <v-container>
    <v-card>
      <v-card-text>
        <h3>Workshop</h3>
        <br />
        <ChallengesAndOpportunities />
        <br />
        <br />
        <h3>Create primary actions from your workshop</h3>
        <ActionPlan />
        <br />
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import _ from "lodash";
// import ActionsReview from "@/components/ActionsReview.vue";
import ChallengesAndOpportunities from "@/components/ChallengesAndOpportunities.vue";
import ActionPlan from "@/components/ActionPlan.vue";

export default {
  name: "App",
  components: { ChallengesAndOpportunities, ActionPlan },
  data: () => ({
    primaryActionsHeaders: [
      { text: "", value: "", sortable: false },
      { text: "Action", value: "Statement" },
      { text: "Person Responsible", value: "ActionedBy.Name" },
      { text: "Status", value: "Status" },
      { text: "Details", value: "Details" },
    ],
    test: 0,
  }),
  mounted() {
    if (this.$store.state.plan.DigitalPlan.OtherActionsListSummary != null) {
      this.actionsListSummaryProxy =
        this.$store.state.plan.DigitalPlan.OtherActionsListSummary;
    }
  },
  computed: {
    primaryActionsComputed() {
      return this.$store.state.plan.DigitalPlan.ActionPlan;
    },
  },

  methods: {
    getStatusColor(status) {
      if (status == "Complete" || status == "Completed") {
        return "green";
      }
      if (status == "In Progress" || status == "In progress") {
        return "orange";
      }
      if (status == "Blocked" || status == "Blocked") {
        return "red";
      }
      if (
        status == "Not started" ||
        status == "Not Started" ||
        status == null
      ) {
        return "blue";
      }
    },
  },
};
</script>

<style scoped></style>
