<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="my-n4">
        <v-radio-group v-model="typeOfAim" dense row class="justify-center">
          <v-radio
            label="Team Aim"
            value="TeamAim"
            @change="radioFunct('TeamAim')"
          ></v-radio>
          <v-radio
            label="Client Aim"
            value="ClientAim"
            @change="radioFunct('ClientAim')"
          ></v-radio>
          <v-radio
            value="PainPoint"
            label="Pain Point"
            @change="radioFunct('PainPoint')"
          ></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="mb-n5">
        <v-select
          v-model="fields.point"
          :items="pointOrAim"
          :item-text="status"
          :label="label"
          :disabled="label === 'Select a button above'"
          @change="changeDesc(fields.point, status)"
        ></v-select>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="py-2">
        <v-textarea
          v-model="fields.description"
          label="Description"
          no-resize
          readonly
          rows="1"
          text-color="grey"
        ></v-textarea>
      </v-col>
    </v-row>

    <v-row v-if="label != 'Select a pain point'">
      <v-col cols="12" class="py-2">
        <v-textarea
          v-model="fields.discipline"
          no-resize
          label="Discipline"
          readonly
          rows="1"
          text-colour="grey"
        ></v-textarea>
      </v-col>
    </v-row>

    <!--
    <v-row>
      <v-col cols="12" class="py-2">
        <v-textarea
          v-model="fields.statement"
          no-resize
          rows="1"
          :rules="stringRules"
          label="Opportunity Statement"
        ></v-textarea>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-select
          v-model="fields.priority"
          :items="priority"
          label="Priority"
        ></v-select>
      </v-col>
    </v-row>
-->

    <v-row>
      <v-col cols="4" align="right">
        <!-- <v-btn block @click="debug">DEBUG</v-btn> -->
      </v-col>
      <v-col cols="4" align="left">
        <v-btn dark block class="blue" @click="cancel">CANCEL</v-btn>
      </v-col>
      <v-col cols="4" align="right">
        <v-btn
          block
          :disabled="active"
          :dark="!active"
          :class="{ blue: !active }"
          @click="submit(fields)"
          >ADD</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DisciplineSelector from "@/components/DisciplineSelector.vue";

export default {
  data: () => ({
    pointOrAim: [],
    TeamAimData: null,
    ClientAimData: null,
    PainData: null,
    status: null,
    priority: ["High", "Medium", "Low"],
    dialog: true,
    label: "Select a button above",
    active: true,

    typeOfAim: null,

    fields: {
      point: null,
      description: null,
      //statement: null,
      //priority: null,
      discipline: null,
    },

    stringRules: [
      (v) => !!v || "Statement is required",
      (v) =>
        (v && v.length <= 150) || "Statement must be less than 150 characters",
    ],
  }),

  components: {
    DisciplineSelector,
  },

  methods: {
    debug() {
      console.log(this.pointOrAim);
    },

    presenceCheck() {
      if (this.label == "Select a pain point") {
        this.fields.discipline = "Pain Point";
      }
      for (const field in this.fields) {
        if (this.fields[field] !== null && field === "discipline") {
          if (this.fields[field] !== "") {
            this.active = false;
          } else {
            this.active = true;
          }
        }

        if (this.fields[field] !== null && field === "statement") {
          if (
            this.fields[field].length > 150 ||
            this.fields[field].length == 0
          ) {
            this.active = true;
            break;
          }
        } else if (this.fields[field] === null || this.fields[field] === "") {
          // keep button disabled
          this.active = true;
          break;
        }
      }
    },
    radioFunct(id) {
      this.pointOrAim = [];
      this.fields.point = null;
      this.fields.description = null;
      this.fields.discipline = null;
      this.status = null;

      if (id == "ClientAim") {
        this.label = "Select a client aim";
        this.pointOrAim = this.ClientAimData;
        this.status = "Value";
      } else if (id == "TeamAim") {
        this.label = "Select a team aim";
        this.pointOrAim = this.TeamAimData;
        this.status = "Value";
      } else if (id == "PainPoint") {
        this.label = "Select a pain point";
        this.pointOrAim = this.PainData;
        this.status = "Name";
      }
    },

    changeDesc(point, status) {
      if (status == "Value") {
        for (const x in this.pointOrAim) {
          if (point === this.pointOrAim[x].Value) {
            this.fields.description = this.pointOrAim[x].Description;
            this.fields.discipline = this.pointOrAim[x].Discipline;
          } else {
          }
        }
      } else {
        for (const x in this.pointOrAim) {
          if (point === this.pointOrAim[x].Name) {
            this.fields.description = this.pointOrAim[x].Symptoms;
          }
        }
      }
    },

    submit(data) {
      this.$emit("update:fields", data);
      this.fields.point = null;
      this.fields.description = null;
      this.fields.priority = null;
      this.fields.discipline = null;
    },

    updateD(e) {
      this.fields.discipline = e;
    },

    cancel() {
      this.$emit("update:closeDialog", false);
    },

    refreshArrays() {
      this.TeamAimData = this.getTeamAims;
      this.ClientAimData = this.getClientAims;
      this.PainData = this.getPainPoints;
    },
  },

  computed: {
    getTeamAims: {
      get: function () {
        return this.$store.state.plan.DigitalPlan.TeamAims;
      },
    },
    getClientAims: {
      get: function () {
        return this.$store.state.plan.DigitalPlan.ClientAims;
      },
    },
    getPainPoints: {
      get: function () {
        return this.$store.state.plan.DigitalPlan.PainPoints;
      },
    },
  },

  watch: {
    fields: {
      handler() {
        this.presenceCheck();
      },
      deep: true,
    },
  },

  mounted() {
    this.refreshArrays();
  },
};
</script>

<style>
.v-textarea {
  border: 0px solid #e20b0b;
  font-size: 14px;
}
</style>
