<template>
  <div class="home">
    <NavigationBar :JobCode="jobCode" />
    <v-container>
      <PlanHeader :title="'Workshop'" />
      <v-row>
        <v-col class="px-0" cols="2">
          <v-btn block v-if="expand == false" @click="expand = !expand"
            ><span> Expand All </span> <v-icon right>mdi-chevron-down </v-icon>
          </v-btn>
          <v-btn block v-if="expand == true" @click="expand = !expand">
            <span> Collapse All </span> <v-icon right>mdi-chevron-up </v-icon>
          </v-btn>
        </v-col>

        <v-col cols="7"></v-col>

        <v-col cols="2">
          <div></div>
          <v-btn block @click="lockDIP()">
            <div v-if="$store.state.plan.DigitalPlan.Lock == true">
              <span>Unlock DIP</span>
              <v-icon right>mdi-lock-open-variant </v-icon>
            </div>
            <div v-else>
              <span> Lock DIP </span> <v-icon right>mdi-lock </v-icon>
            </div>
          </v-btn>
        </v-col>

        <v-col class="px-0" cols="1">
          <div class="text-right">
            <v-btn class="saveButton" @click="saveButtonOnClick()"> </v-btn>
          </div>
        </v-col>
      </v-row>

      <div v-if="showWarning">
        <v-row>
          <v-col class="px-0" cols="4">
            <v-alert dense border="left" type="warning">
              You are on the UAT / Test server!
            </v-alert>
          </v-col>
        </v-row>
      </div>
      <v-row>
        <v-col>
          <div>
            <v-divider class="mb-7 mt-1" color="#E0E0E0"></v-divider>
            <WorkshopExpansionPanel :expandAll="expand" />
          </div>
        </v-col>
      </v-row>
      <v-row class="mt-7">
        <v-col>
          <NavigationButton
            v-bind:routeName="'ResearchPreWork'"
            v-bind:label="'Back'"
          />
        </v-col>
        <v-col align="center" justify="center"> </v-col>
        <v-col>
          <div align="right">
            <NavigationButton
              v-bind:routeName="'Actions'"
              v-bind:label="'Next'"
            />
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import WorkshopExpansionPanel from "@/components/WorkshopExpansionPanel.vue";
import PlanHeader from "@/components/PlanHeader.vue";
import NavigationBar from "@/components/NavigationBar.vue";
import NavigationButton from "@/components/NavigationButton.vue";
import mixin from "../mixins/mixin.js";
export default {
  mixins: [mixin],
  components: {
    WorkshopExpansionPanel,
    PlanHeader,
    NavigationButton,
    NavigationBar,
  },
  computed: {
    jobCode() {
      return this.$store.state.plan.JobCode;
    },
    lastModified() {
      return this.$store.state.lastModified;
    },
    showWarning() {
      if (
        this.$store.state.ApiUri !=
        "https://dip-prod-function-app.azurewebsites.net/api"
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  data: () => ({
    expand: false,
    loading: false,
  }),
  created() {
    // This if statement checks to see if a plan is loaded upon landing on this route
    // If a plan is not loaded, redirect to Home page. else do nothing.
    if (this.$store.state.plan.JobCode == null) {
      this.$router.push({ name: "Home" });
    }
  },
  methods: {
    async lockDIP() {
      if (this.$store.state.plan.DigitalPlan.Lock == null) {
        this.$store.state.plan.DigitalPlan.Lock = true;
      } else {
        this.$store.state.plan.DigitalPlan.Lock =
          !this.$store.state.plan.DigitalPlan.Lock;
      }
      this.$store.state.plan.DigitalPlan = await this.packetUpdateDIP(
        this.$store.state.plan,
        this.$store.state.ApiUri,
        "Lock"
      );
    },

    saveButtonOnClick() {
      // this.fullUpdateDIP(this.$store.state.plan, this.$store.state.ApiUri);
    },
  },
};
</script>
