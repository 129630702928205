<template>
  <v-container>
    <v-row>
      <v-col align="center">
        <v-text-field
          width="500"
          readonly
          label="Add a Challenge or Opportunity..."
          @click="openDialog = true"
        ></v-text-field>
        <div v-if="openDialog">
          <v-dialog v-model="openDialog" width="500">
            <v-card class="removeScroll">
              <v-card-title class="headline grey lighten-2">
                Create an Opportunity
              </v-card-title>
              <v-container fill-height>
                <v-row justify="center" align="center">
                  <v-col cols="12">
                    <v-form ref="disc">
                      <WorkshopPopUpNew
                        @update:fields="setChalOp"
                        @update:closeDialog="openDialog = false"
                      />
                    </v-form>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-dialog>
        </div>
      </v-col>
    </v-row>

    <!-- 
    <v-row>
      <v-col cols="8">
        <v-select
          v-model="tempAction.ChallengeOpportunity"
          :items="ChallengesOpportunities"
          label="Challenge/Opportunity"
          item-text="point"
          return-object
          @change="pullData()"
        >
          <template v-slot:item="aim">
            <div class="pt-3">
              <h3>{{ aim.item.point }}</h3>
              <p>{{ truncateString(aim.item.description) }}</p>
            </div>
          </template>
        </v-select>
      </v-col>

      <v-col cols="4">
        <div v-if="tempAction.ChallengeOpportunity.Priority == 'High'">
          High Priority
        </div>
        <div v-if="tempAction.ChallengeOpportunity.Priority == 'Medium'">
          Medium Priority
        </div>
        <div v-if="tempAction.ChallengeOpportunity.Priority == 'Low'">
          Low Priority
        </div>
      </v-col>
    </v-row>
      -->

    <!--
    <v-row>
      <v-col cols="4"> </v-col>
      <v-col cols="8">
        The (change that will be made) for the benefit of (team/client) by
        (actors/team)
      </v-col>
    </v-row>
-->
    <v-row>
      <v-col cols="4"> Challenge / Opportunity statement </v-col>
      <v-col cols="8">
        <v-text-field readonly v-model="tempActionChalOps"> </v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="4"> How will this benefit things? </v-col>
      <v-col cols="8">
        <v-text-field v-model="tempAction.Benefit"> </v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="4"> What will we do? </v-col>
      <v-col cols="8">
        <v-text-field v-model="tempAction.Action"> </v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="4">
        Who will we collaborate with ? (Internal / External)
        <v-tooltip top max-width="600">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on"> mdi-information-outline </v-icon>
          </template>
          <span
            >Add free text, could be a specific person, place (eg. Yammer/skills
            network) or a TBC</span
          >
        </v-tooltip>
      </v-col>
      <v-col cols="8">
        <v-text-field v-model="tempAction.Collaborate"> </v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="4">
        How will we do it - Which tools/processes/systems will we use?
        <v-tooltip top max-width="600">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on"> mdi-information-outline </v-icon>
          </template>
          <span
            >Remember to use the Tools Register here to Adopt and/or
            Adapt.</span
          >
        </v-tooltip>
      </v-col>
      <v-col cols="8">
        <v-text-field v-model="tempAction.Method"> </v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="4">
        What extra training or learning do we need to deliver this ?
        <v-tooltip top max-width="600">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on"> mdi-information-outline </v-icon>
          </template>
          <span
            >Do you have the skills in your team to deliver this, or do you need
            additional resource?.</span
          >
        </v-tooltip>
      </v-col>
      <v-col cols="8">
        <v-text-field v-model="tempAction.Training"> </v-text-field>
      </v-col>
    </v-row>

    <!--
    <v-row>
      <v-col cols="4">
        Adapt/Adopt/Invent
        <v-tooltip top max-width="600">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on"> mdi-information-outline </v-icon>
          </template>
          <span
            >Is this challenge/opportunity an example of adopting an existing
            tool/workflow, adapting it for your needs, or inventing something
            new? Remember adopting what is already out there should be
            encouraged to drive efficiencies on your project!
          </span>
        </v-tooltip>
      </v-col>
      <v-col cols="4">
        <v-select
          v-model="tempAction.Category"
          :items="['Adopt', 'Adapt', 'Invent']"
          label="Select an option"
        ></v-select>
      </v-col>
      <v-col cols="4">
        Reminder about contract state
        <v-tooltip top max-width="600">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on"> mdi-information-outline </v-icon>
          </template>
          <span
            >Remember to review your contract on IP. If you really need to
            invent a solution, ensure it is safeguarded to be used on other
            projects by other teams.</span
          >
        </v-tooltip>
      </v-col>
    </v-row>
    -->
    <!-- MORE -->

    <v-row>
      <v-col cols="4"> Who will pursue it (person) </v-col>
      <v-col cols="4">
        <v-select
          v-model="tempAction.ActionedBy"
          :items="personnelList"
          item-text="Name"
          item-value="Email"
          return-object
          label="Please select..."
        ></v-select>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="4"> Notes </v-col>
      <v-col cols="7">
        <v-text-field v-model="tempAction.Notes"> </v-text-field>
      </v-col>

      <v-col cols="1">
        <v-btn icon @click="appendItem(tempAction)">
          <v-icon x-large color="#2092D3">mdi-plus-circle-outline </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <!-- DATA TABLE -->

    <v-row>
      <v-col>
        <v-data-table
          :headers="ActionPlanHeaders"
          :items="ActionPlan"
          hide-default-footer
          class="elevation-1"
          disable-pagination
        >
          <template v-slot:item="row">
            <tr>
              <td>{{ truncateString(row.item.Statement) }}</td>
              <td>{{ row.item.ActionedBy.Name }}</td>
              <!-- <td>{{ row.item.Category }}</td> -->

              <td class="tableActions">
                <v-btn
                  icon
                  @click="
                    editDialog = true;
                    selectedItem = row.item;
                    newItem = copyItem(row.item);
                  "
                  class="mdi-pencil"
                >
                </v-btn>
              </td>
              <td class="tableActions">
                <v-btn
                  icon
                  @click="
                    confirmDeleteDialog = true;
                    selectedItem = row.item;
                  "
                  class="mdi-trash-can-outline"
                >
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!-- DIALOGS -->
    <!-- DELETE -->

    <v-dialog v-model="confirmDeleteDialog" max-width="320">
      <v-card>
        <v-card-title class="headline">Confirm action delete</v-card-title>
        <v-card-text
          >Are you sure you would like to delete this action?</v-card-text
        >
        <v-card-actions>
          <v-btn
            color="red darken-3"
            text
            @click="
              confirmDeleteDialog = false;
              selectedItem = null;
            "
            >Cancel</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="
              confirmDeleteDialog = false;
              deleteItem(selectedItem);
            "
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- EDIT -->

    <v-dialog v-model="editDialog" max-width="1100">
      <v-card>
        <v-card-title class="headline">Edit action</v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                readonly
                label="Challenge / Opportunity statement"
                v-model="newItem.Statement"
                placeholder="The <change that will be made> for the benefit of <team/client> by <actors/team>"
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4"> How will this benefit things? </v-col>
            <v-col cols="8">
              <v-text-field v-model="newItem.Benefit"> </v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4"> What will we do? </v-col>
            <v-col cols="8">
              <v-text-field v-model="newItem.Action"> </v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4">
              Who will we collaborate with ? (Internal / External)
              <v-tooltip top max-width="600">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">
                    mdi-information-outline
                  </v-icon>
                </template>
                <span
                  >Add free text, could be a specific person, place (eg.
                  Yammer/skills network) or a TBC</span
                >
              </v-tooltip>
            </v-col>
            <v-col cols="8">
              <v-text-field v-model="newItem.Collaborate"> </v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4">
              How will we do it - Which tools/processes/systems will we use?
              <v-tooltip top max-width="600">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">
                    mdi-information-outline
                  </v-icon>
                </template>
                <span
                  >Remember to use the Tools Register here to Adopt and/or
                  Adapt.</span
                >
              </v-tooltip>
            </v-col>
            <v-col cols="8">
              <v-text-field v-model="newItem.Method"> </v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4">
              What extra training or learning do we need to dliver this ?
              <v-tooltip top max-width="600">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">
                    mdi-information-outline
                  </v-icon>
                </template>
                <span
                  >Do you have the skills in your team to deliver this, or do
                  you need additional resource?.</span
                >
              </v-tooltip>
            </v-col>
            <v-col cols="8">
              <v-text-field v-model="newItem.Training"> </v-text-field>
            </v-col>
          </v-row>

          <!-- MORE -->

          <v-row>
            <v-col cols="4"> Who will pursue it (person) </v-col>
            <v-col cols="4">
              <v-select
                v-model="newItem.ActionedBy"
                :items="personnelList"
                item-text="Name"
                item-value="Email"
                return-object
                label="Please select..."
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4"> Notes </v-col>
            <v-col cols="8">
              <!-- TODO replace -->
              <v-text-field v-model="newItem.Notes"> </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn color="red darken-3" text @click="editDialog = false"
            >Cancel</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="
              editDialog = false;
              editItem(selectedItem, newItem);
            "
            >Save changes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import _ from "lodash";
import mixin from "../mixins/mixin.js";
import WorkshopPopUpNew from "@/components/WorkshopPopUpNew.vue";

export default {
  mixins: [mixin],
  props: {},
  components: { WorkshopPopUpNew },

  computed: {
    tempActionChalOps() {
      return this.tempAction.ChallengeOpportunity.description;
    },
    ChallengesOpportunities: function () {
      return this.$store.state.plan.DigitalPlan.ChallengesOpportunities;
    },
    personnelList() {
      let group = [];
      if (this.$store.state.plan.ProjectDirector.Name != null) {
        group.push(this.$store.state.plan.ProjectDirector);
      }
      if (this.$store.state.plan.ProjectManager.Name != null) {
        group.push(this.$store.state.plan.ProjectManager);
      }
      if (this.$store.state.plan.DigitalPlan.DigitalLead != null) {
        if (this.$store.state.plan.DigitalPlan.DigitalLead.Name != null) {
          this.$store.state.plan.DigitalPlan.DigitalLead;
        }
      }
      if (this.$store.state.plan.DigitalPlan.DigitalFacilitator != null) {
        if (
          this.$store.state.plan.DigitalPlan.DigitalFacilitator.Name != null
        ) {
          group.push(this.$store.state.plan.DigitalPlan.DigitalFacilitator);
        }
      }
      this.$store.state.plan.DigitalPlan.OtherAttendees.forEach((element) => {
        if (element.Name != null) {
          group.push(element);
        }
      });
      this.$store.state.plan.DigitalPlan.DisciplineLeads.forEach((element) => {
        group.push(element.Lead);
        if (element.Lead.Name != null) {
          group.push(element.Lead);
        }
      });
      return group;
    },
    ActionPlan() {
      return this.$store.state.plan.DigitalPlan.ActionPlan;
    },
  },

  data: () => ({
    ActionPlanHeaders: [
      { text: "Statement", value: "Statement", sortable: true },
      { text: "Assigned to", value: "ActionedBy.Name", sortable: false },
      // { text: "Category", value: "Category", sortable: false },
      { text: "", value: "Edit", sortable: false },
      { text: "", value: "Delete", sortable: false },
    ],
    openDialog: false,

    // ActionPlan: [],

    tempAction: {
      Id: null,
      ChallengeOpportunity: {
        Id: null,
        Name: null,
        Type: null,
        Priority: null,
      },
      Statement: null,
      Benefit: null,
      Action: null,
      Collaborate: null,
      Method: null,
      Training: null,
      Category: null,
      ActionedBy: {
        Name: null,
        Email: null,
      },
      Notes: null,
      Status: null,
      Updates: [],
      DeadlineDate: null,
    },

    newItem: {
      Id: null,
      ChallengeOpportunity: {
        Id: null,
        Name: null,
        Type: null,
        Priority: null,
      },
      Statement: null,
      Benefit: null,
      Action: null,
      Collaborate: null,
      Method: null,
      Training: null,
      Category: null,
      ActionedBy: {
        Name: null,
        Email: null,
      },
      Notes: null,
      Status: null,
      Updates: [
        {
          Id: null,
          Content: null,
          DateAdded: null,
        },
      ],
      DeadlineDate: null,
    },

    confirmDeleteDialog: false,
    editDialog: false,
    selectedItem: {
      Id: null,
      ChallengeOpportunity: {
        Id: null,
        Name: null,
        Type: null,
        Priority: null,
      },
      Statement: null,
      Benefit: null,
      Action: null,
      Collaborate: null,
      Method: null,
      Training: null,
      Category: null,
      ActionedBy: {
        Name: null,
        Email: null,
      },
      Notes: null,
      Status: null,
      Updates: [
        {
          Id: null,
          Content: null,
          DateAdded: null,
        },
      ],
      DeadlineDate: null,
    },
  }),
  mounted() {},
  methods: {
    setChalOp(item) {
      this.openDialog = false;
      let newItem = _.cloneDeep(item);
      newItem.Id = this.$uuid.v4();
      this.tempAction.ChallengeOpportunity = _.cloneDeep(newItem);
      this.tempAction.Statement = newItem.description;
    },
    truncateString(val) {
      if (val) {
        if (val.length > 60) {
          return val.match(/.{1,60}/g)[0].concat("...");
        } else {
          return val;
        }
      }
    },

    copyItem(item) {
      return _.cloneDeep(item);
    },
    async appendItem(item) {
      // copy aim

      let newItem = _.cloneDeep(item);
      // let newItem = Object.create(item);
      // create new uuid for aim
      newItem.Id = this.$uuid.v4();
      // push into local array
      this.ActionPlan.push(newItem);
      // put local array in store
      this.$store.state.plan.DigitalPlan.ActionPlan = this.ActionPlan;
      this.$store.state.plan.DigitalPlan = await this.packetUpdateDIP(
        this.$store.state.plan,
        this.$store.state.ApiUri,
        "ActionPlan"
      );
      // reset tempAim variable to
      this.tempAction = {
        Id: null,
        ChallengeOpportunity: {
          Id: null,
          Name: null,
          Type: null,
          Priority: null,
        },
        Statement: null,
        Benefit: null,
        Action: null,
        Collaborate: null,
        Method: null,
        Training: null,
        Category: null,
        ActionedBy: {
          Name: null,
          Email: null,
        },
        Notes: null,
        Status: null,
        Updates: [
          {
            Id: null,
            Content: null,
            DateAdded: null,
          },
        ],
        DeadlineDate: null,
      };
    },
    async deleteItem(item) {
      this.ActionPlan.splice(
        this.ActionPlan.findIndex((e) => e.Id == item.Id),
        1
      );
      this.$store.state.plan.DigitalPlan.ActionPlan = this.ActionPlan;
      this.$store.state.plan.DigitalPlan = await this.packetUpdateDIP(
        this.$store.state.plan,
        this.$store.state.ApiUri,
        "ActionPlan"
      );
      this.selectedItem = {
        Id: null,
        ChallengeOpportunity: {
          Id: null,
          Name: null,
          Type: null,
          Priority: null,
        },
        Statement: null,
        Benefit: null,
        Action: null,
        Collaborate: null,
        Method: null,
        Training: null,
        Category: null,
        ActionedBy: {
          Name: null,
          Email: null,
        },
        Notes: null,
        Status: null,
        Updates: [
          {
            Id: null,
            Content: null,
            DateAdded: null,
          },
        ],
        DeadlineDate: null,
      };
    },
    async editItem(selectedItem, newItem) {
      const index = this.ActionPlan.findIndex((x) => x.Id == selectedItem.Id);
      if (index > -1) {
        this.ActionPlan[index].ChallengeOpportunity =
          newItem.ChallengeOpportunity;
        this.ActionPlan[index].Statement = newItem.Statement;
        this.ActionPlan[index].Benefit = newItem.Benefit;
        this.ActionPlan[index].Action = newItem.Action;
        this.ActionPlan[index].Collaborate = newItem.Collaborate;
        this.ActionPlan[index].Method = newItem.Method;
        this.ActionPlan[index].Training = newItem.Training;
        this.ActionPlan[index].Category = newItem.Category;
        this.ActionPlan[index].ActionedBy = newItem.ActionedBy;
        this.ActionPlan[index].Notes = newItem.Notes;
        this.ActionPlan[index].Status = newItem.Status;
        this.ActionPlan[index].Updates = newItem.Updates;
        this.ActionPlan[index].DeadlineDate = newItem.DeadlineDate;

        this.$store.state.plan.DigitalPlan.ActionPlan = this.ActionPlan;
        this.$store.state.plan.DigitalPlan = await this.packetUpdateDIP(
          this.$store.state.plan,
          this.$store.state.ApiUri,
          "ActionPlan"
        );
        this.selectedItem = {
          Id: null,
          ChallengeOpportunity: {
            Id: null,
            Name: null,
            Type: null,
            Priority: null,
          },
          Statement: null,
          Benefit: null,
          Action: null,
          Collaborate: null,
          Method: null,
          Training: null,
          Category: null,
          ActionedBy: {
            Name: null,
            Email: null,
          },
          Notes: null,
          Status: null,
          Updates: [
            {
              Id: null,
              Content: null,
              DateAdded: null,
            },
          ],
          DeadlineDate: null,
        };
        this.newItem = {
          Id: null,
          ChallengeOpportunity: {
            Id: null,
            Name: null,
            Type: null,
            Priority: null,
          },
          Statement: null,
          Benefit: null,
          Action: null,
          Collaborate: null,
          Method: null,
          Training: null,
          Category: null,
          ActionedBy: {
            Name: null,
            Email: null,
          },
          Notes: null,
          Status: null,
          Updates: [
            {
              Id: null,
              Content: null,
              DateAdded: null,
            },
          ],
          DeadlineDate: null,
        };
      } else {
        console.log("error updating action plan");
      }
    },
  },
};
</script>

<style scoped></style>
