<template>
  <v-container>
    <v-row>
      <v-col>
        In this step we would like you to consider the client aims, team aims
        and pain points and turn them into challenges and opportunties on the
        project. <br />
        Once complete, in the next section you will chose 4-5 to focus on and
        add detail.
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="1"></v-col>
      <v-col cols="5">
        Want to use a whiteboard?
        <v-btn
          dark
          color="blue"
          :href="whiteboardTemplateLink"
          target="_blank"
          class="ma-2"
          >Get the template</v-btn
        >
        <v-tooltip top max-width="600">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on"> mdi-information-outline </v-icon>
          </template>
          <span
            >With your digital facilitator, unpack the team aims, client aims
            and pain points and convert them into challenges and opportunities.
            Use the whiteboard as a template, feel free to modify to your needs.
            You could use this space to explore how the existing workflow looks
            and its challenges. The priority of the challenges/opportunities
            will be dependant on the project.</span
          >
        </v-tooltip>
        <!-- <a href="https://wbd.ms/share/v2/aHR0cHM6Ly93aGl0ZWJvYXJkLm1pY3Jvc29mdC5jb20vYXBpL3YxLjAvd2hpdGVib2FyZHMvcmVkZWVtL2FhZDY2MjBiMmI4NTQyYzRhM2FiMmFlYmJhN2M5MzgxXzRhZTQ4YjQxLTAxMzctNDU5OS04NjYxLWZjNjQxZmU3N2JlYQ==" target="_blank"> Template </a> -->
      </v-col>
      <v-col cols="4">
        <v-text-field
          label="Paste your Whiteboard URL here"
          v-model="LinkToWhiteboard"
          @change="updateLink()"
        >
        </v-text-field>
      </v-col>
      <v-col cols="1">
        <v-tooltip top max-width="600">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on"> mdi-information-outline </v-icon>
          </template>
          <span
            >Copy and paste from the existing template into your own DIP
            specific whiteboard and paste the share link in this box for future
            reference.</span
          >
          <v-col cols="1"></v-col>
        </v-tooltip>
      </v-col>
    </v-row>

    <br />
    <!--opportunities-->
    <v-divider class="my-6"></v-divider>

    <v-dialog v-model="confirmDeleteDialog" max-width="320">
      <v-card>
        <v-card-title class="headline">Confirm client aim delete</v-card-title>
        <v-card-text
          >Are you sure you would like to delete this client aim?</v-card-text
        >
        <v-card-actions>
          <v-btn
            color="red darken-3"
            text
            @click="
              confirmDeleteDialog = false;
              selectedItem = null;
            "
            >Cancel</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="
              confirmDeleteDialog = false;
              deleteItem(selectedItem);
            "
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editDialog" max-width="600">
      <v-card>
        <v-card-title class="headline grey lighten-2"
          >Edit Challenge / Opportunity</v-card-title
        >
        <v-container class="pa-5">
          <v-form v-model="isFormValid">
            <v-row class="pb-4">
              <v-col cols="12">
                <v-textarea
                  label="Edit Opportunity Statement"
                  :rules="[rules.descPresence, rules.descLength]"
                  rows="2"
                  :value="newItem.description"
                  v-model="newItem.description"
                  @change="changed = true"
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <DisciplineSelector
                  @update:model="updateD"
                  :outerButton="{ button: false, symbol: '' }"
                  :initialValue="newItem.discipline"
                ></DisciplineSelector>
              </v-col>
            </v-row>
          </v-form>
        </v-container>

        <v-card-actions>
          <v-btn
            color="red darken-3"
            text
            @click="
              editDialog = false;
              wipe();
            "
            >Cancel</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            :disabled="validationCheck()"
            @click="
              editDialog = false;
              editItem(selectedItem, newItem);
            "
            >Save changes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import WorkshopPopUp from "@/components/WorkshopPopUp.vue";
import DisciplineSelector from "@/components/DisciplineSelector.vue";
import _ from "lodash";
import mixin from "../mixins/mixin.js";

export default {
  mixins: [mixin],
  name: "App",
  props: {},
  components: {
    WorkshopPopUp,
    DisciplineSelector,
  },

  data: () => ({
    openDialog: false,
    confirmDeleteDialog: false,
    editDialog: false,
    LinkToWhiteboard: null,
    isFormValid: false,
    changed: false,

    AimOrPain: [],
    AimOrPainHeaders: [
      // new headers for new data
      { text: "Aim/Pain title", value: "Point", sortable: true },
      { text: "Discipline", value: "Discipline", sortable: true },
      { text: "Opportunity Statement", value: "Description", sortable: false },
      // { text: "Statement", value: "Statement", sortable: false },
      // { text: "Priority", value: "Priority", sortable: true },
      { text: null, sortable: false },
      { text: null, sortable: false },
    ],

    // new object to replace tempChallenges and tempOpportunities

    rules: {
      descPresence: (v) => !!v || "Required",
      descLength: (v) => (v && v.length <= 150) || "150 characters max",
    },

    tempAimOrPain: {
      Description: null,
      Statement: null,
      Priority: null,
      Discipline: null,
    },

    selectedItem: {
      Id: null,
      Point: null,
      Description: null,
      Statement: null,
      Priority: null,
      Discipline: null,
    },

    newItem: {
      Id: null,
      Point: null,
      Description: null,
      Statement: null,
      Priority: null,
      Discipline: null,
    },
  }),

  computed: {
    ChallengesOpportunities() {
      return this.$store.state.plan.DigitalPlan.ChallengesOpportunities;
    },
  },

  methods: {
    validationCheck() {
      if (this.isFormValid == true && this.changed == true) {
        if (
          this.selectedItem.priority != null &&
          this.selectedItem.discipline != null
        ) {
          console.log("Changes have been made - 'Save Changes' button enabled");
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },

    add(D) {
      this.tempAimOrPain.Discipline = D;
      this.selectedItem.discipline = D;
    },
    updateD(D) {
      this.newItem.discipline = D;
      if (
        this.newItem.discipline === null ||
        this.newItem.discipline === "Other" ||
        this.newItem.discipline == ""
      ) {
        this.changed = false;
      } else {
        this.changed = true;
      }
    },
    wipe() {
      for (const field in this.newItem) {
        this.newItem[field] = null;
      }
      this.newItem.discipline = null;
      this.newItem.priority = null;
    },

    appendItem(item) {
      this.openDialog = false;
      let newItem = _.cloneDeep(item);
      newItem.Id = this.$uuid.v4();
      this.AimOrPain.push(newItem);
    },

    editItem(selectedItem, newItem) {
      const index = this.AimOrPain.findIndex((x) => x.Id == selectedItem.Id);
      if (index > -1) {
        this.AimOrPain[index].description = newItem.description;
        this.AimOrPain[index].statement = newItem.statement;
        this.AimOrPain[index].priority = newItem.priority;
        this.AimOrPain[index].discipline = newItem.discipline;

        this.$store.state.plan.DigitalPlan.ChallengesOpportunities =
          this.AimOrPain;

        this.changed = false;

        this.selectedItem = {
          Id: null,
          Point: null,
          Description: null,
          Priority: null,
          Discipline: null,
        };
        this.newItem = {
          Id: null,
          Point: null,
          Description: null,
          Priority: null,
          Discipline: null,
        };
      } else {
        console.log("error updating challenge/opportunity");
      }
    },

    deleteItem(item) {
      this.AimOrPain.splice(
        this.AimOrPain.findIndex((e) => e.Id == item.Id),
        1
      );

      this.$store.state.plan.DigitalPlan.ChallengesOpportunities =
        this.AimOrPain;
    },

    indexData(tableData) {
      for (const x in tableData) {
        tableData[x].Id = x;
      }
      console.log(this.$store.state.plan.DigitalPlan.ChallengesOpportunities);
    },

    copyItem(item) {
      return _.cloneDeep(item);
    },

    truncateString(val) {
      if (val) {
        if (val.length > 60) {
          return val.match(/.{1,60}/g)[0].concat("...");
        } else {
          return val;
        }
      }
    },
    async updateLink() {
      this.$store.state.plan.DigitalPlan.LinkToWhiteboard =
        this.LinkToWhiteboard;
      this.$store.state.plan.DigitalPlan = await this.packetUpdateDIP(
        this.$store.state.plan,
        this.$store.state.ApiUri,
        "LinkToWhiteboard"
      );
    },
  },

  mounted() {
    this.AimOrPain = this.getStoreData;
    this.LinkToWhiteboard = this.$store.state.plan.DigitalPlan.LinkToWhiteboard;
  },

  computed: {
    getStoreData: {
      get: function () {
        return this.$store.state.plan.DigitalPlan.ChallengesOpportunities;
      },
    },
    whiteboardTemplateLink: {
      get: function () {
        return this.$store.getters.getWhiteboardTemplateLink;
      },
    },
  },

  watch: {
    AimOrPain: {
      handler() {
        // this.indexData(this.AimOrPain);
      },
      deep: true,
    },
  },
};
</script>

<style src="../styles/forms.scss" lang="scss"></style>

<style src="../styles/table.scss" lang="scss"></style>
